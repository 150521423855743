import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import CustomersLogos from '../components/CustomersLogos';
import FeaturesCard from '../components/FeaturesCard';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const RowIconsCells = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    margin: 5% 0%;

    @media (min-width: 320px) and (max-width: 767px) {
        flex-direction:column;
    }
`;

const IconContent = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: baseline;

    @media (min-width: 320px) and (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`;

const RowIconsDescription = styled.div`
    margin: 15px;
    width: 20%;

    @media (min-width: 320px) and (max-width: 767px) {
        margin: 9px;
        width: 43%;
    }
`;

const Picture = styled.img`
    max-width: 2vh;
`;

const TitleCell = styled.h2`
    font-size: 16px;
    font-weight: 300;
`;

const TextCell = styled.p`
    font-size: 14px;
    font-weight: 200;
`;

const IndexPage = ({ data }) => {
    const { t } = useTranslation('wemapDevelopersPage');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_DEVELOPER')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_DEVELOPER')}/>
            </Helmet>
            <Layout>
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>
                    <CustomersLogos title={t('TITLE_DEV_TOOLS')}
                        size='large'
                        customersLogos={[
                            {image: '/images/products/wemap-web/air-france.svg'},
                            {image: '/images/products/wemap-developers/logo_telegramme.png'},
                            {image: '/images/products/wemap-developers/nrco_logo.png'},
                            {image: '/images/products/wemap-developers/logo_tous_voisins.png'},
                            {image: '/images/products/wemap-developers/mcc-logo.png'}
                        ]} />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_DEVELOPERS')}
                        cellParagraph={t('DESCRIPTION_MAP_RICH_CONTENT')}
                        pictureRight={data.developersScreen.childImageSharp}
                        altDescription='iPad with code and Wemap map'
                        titleButton={t('BUTTON_SEE_DOCUMENTATION')}
                        urlFooter='https://developers.getwemap.com'
                    />

                    <RowIconsCells>
                        <IconContent>
                            <RowIconsDescription>
                                <Picture src='/images/products/wemap-developers/icon_content_copy.png'
                                    alt='' />
                                <TitleCell>{t('TITLE_CORE')}</TitleCell>
                                <TextCell>{t('DESCRIPTION_CORE')}</TextCell>
                            </RowIconsDescription>

                            <RowIconsDescription>
                                <Picture src='/images/products/wemap-developers/icon_road.png'
                                    alt='' />
                                <TitleCell>{t('TITLE_ITINERARIES')}</TitleCell>
                                <TextCell>{t('DESCRIPTION_ITINERARIES')}</TextCell>
                            </RowIconsDescription>

                            <RowIconsDescription>
                                <Picture src='/images/products/wemap-developers/icon_map-marker.png'
                                    alt='' />
                                <TitleCell>{t('TITLE_GEOCODING')}</TitleCell>
                                <TextCell>{t('DESCRIPTION_GEOCODING')}</TextCell>
                            </RowIconsDescription>

                            <RowIconsDescription>
                                <Picture src='/images/products/wemap-developers/icon_plane.png'
                                    alt='' />
                                <TitleCell>{t('TITLE_OFFLINE')}</TitleCell>
                                <TextCell>{t('DESCRIPTION_OFFLINE')}</TextCell>
                            </RowIconsDescription>

                            <RowIconsDescription>
                                <Picture src='/images/products/wemap-developers/icon_wordpress.png'
                                    alt='' />
                                <TitleCell>{t('TITLE_WORDPRESS')}</TitleCell>
                                <TextCell>{t('DESCRIPTION_WORDPRESS')}</TextCell>
                            </RowIconsDescription>
                        </IconContent>
                    </RowIconsCells>

                    <FeaturesCard
                        title={t('TITLE_RESOURCES_DEV')}
                        text={t('DESCRIPTION_RESOURCES_DEV')}
                        titleCard={t('DESCRIPTION_WEMAP_COVERED')}
                        textCard={t('DESCRIPTION_WEMAP_COVERED')}
                        url='/products/'
                        linkButton={t('TITLE_BUTTON_DISCOVER_WEMAP')}
                        color='#2FAAE1'
                        infosCards={[
                            {
                                buttonUrl: 'https://developers.getwemap.com/',
                                externalLink: 'externalLink',
                                firstImageUrl: '/images/home/icon_wemapkiosk.svg',
                                secondImageUrl: '/images/home/icon_wemapar.svg',
                                thirdImageUrl: '/images/home/icon_wemapmobile.svg',
                                fourthImageUrl: '/images/home/icon_experience-seemore.svg',
                                firstTextIcon: t('ICON_TITLE_JS'),
                                secondTextIcon: t('ICON_TITLE_IOS'),
                                thirdTextIcon: t('ICON_TITLE_ANDROID'),
                                fourthTextIcon: t('BUTTON_SEE_DOC'),
                                color: '#2FAAE1',
                                title: t('TITLE_LIBRAIRIES'),
                                text: t('DESCRIPTION_DOCUMENTATION')
                            },
                            {
                                buttonUrl: 'https://developers.getwemap.com/',
                                externalLink: 'externalLink',
                                firstImageUrl: '/images/home/icon_wemapengine.svg',
                                secondImageUrl: '/images/home/icon_wemappro.svg',
                                thirdImageUrl: '/images/home/icon_documentation.svg',
                                fourthImageUrl: '/images/home/icon_tools-seemore.svg',
                                firstTextIcon: t('ICON_TITLE_REST_API'),
                                secondTextIcon: t('ICON_TITLE_PYTHON'),
                                thirdTextIcon: t('ICON_TITLE_ELASTIC_SEARCH'),
                                fourthTextIcon: t('BUTTON_SEE_DOC'),
                                color: '#FFC871',
                                title: t('TITLE_APIS'),
                                text: t('DESCRIPTION_REST_API')
                            },
                            {
                                buttonUrl: '/contact/',
                                firstImageUrl: '/images/home/icon_spreadsheet.svg',
                                secondImageUrl: '/images/home/icon_twitter.svg',
                                thirdImageUrl: '/images/home/icon_uber.svg',
                                fourthImageUrl: '/images/home/icon_app-seemore.svg',
                                firstTextIcon: t('ICON_TITLE_ICAL'),
                                secondTextIcon: t('ICON_TITLE_EVENT_BRITE'),
                                thirdTextIcon: t('ICON_TITLE_UBER'),
                                fourthTextIcon: t('BUTTON_CONTACT_US'),
                                color: '#FF9ACC',
                                title: t('TITLE_MARKETPLACE'),
                                text: t('DESCRIPTION_PARTNERS')
                            }
                        ]}
                    />

                    <SectionOnlyFooter
                        titleSection={t('TITLE_ROCK_SOLID')}
                        cellParagraph={t('DESCRIPTION_ROCK_SOLID')}
                        pictureRight={data.infrastructure.childImageSharp}
                        altDescription='image of iPad content interface'
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        urlFooter='/contact/'
                    />

                    <BannerPicture backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with people working together'
                        titleBannerPicture={t('TITLE_BANNER_CASE_STUDIES')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/' />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  developersScreen: file(
    relativePath: {eq: "products/wemap-developers/wemap_developers_screen.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 520, layout: FIXED)
    }
  }
  infrastructure: file(
    relativePath: {eq: "products/wemap-developers/infrastructure_wemap_developers.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 520, layout: FIXED)
    }
  }
  banner: file(
    relativePath: {eq: "products/wemap-developers/banner_wemap-developers.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
